.home-container {
  background-image: url("./Images/back1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#contact-container {
  background-image: url("./Images/call.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(34, 26, 26, 0.1);
}
